<template>
  <div class="my-avatar" :class="[size ? 'my-avatar__' + size : '']">
    <span class="my-avatar-text" v-if="!img">{{ text }}</span>
    <img class="my-avatar-img" v-else :src="img" alt="">
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    img: String,
    text: String,
    size: String
  }
}
</script>

<style lang="less" scoped>

.my-avatar {
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00B578;
  border-radius: 48px;
  overflow: hidden;
  font-size: 26px;
  &__mini {
    width: 36px;
    height: 36px;
  }
}
.my-avatar-img {
  width: 100%;
  height: 100%;
}
.my-avatar-text {
  font-weight: 400;
  line-height: 48px;
  color: #FFFFFF;
}
</style>