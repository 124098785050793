<template>
  <div class="box">

    <div class="ad-item-box">
      <div class="ad-item">

        <div class="ad-header">

          <div class="ad-header-user">
            <my-avatar class="ad-header-user-avatar" size="mini" :img="item.MerchantInfo.Head" :text="getIcon"></my-avatar>
            <div class="ad-header-user-name">{{item.MerchantInfo.Nick}}</div>
            <div class="ad-header-user-address-box">(<span class="ad-header-user-address">{{item.MerchantInfo.Address}}</span>)</div>
            <div class="ad-header-user-mold">{{ item.Mold == 1 ? '买' : '卖' }}</div>
          </div>
          <div class="ad-header-num">成单量 {{item.MerchantInfo.DealOrder}} | {{DealRate}}%</div>
        </div>

        <div class="ad-content">

          <div class="ad-content-item">
            <div class="ad-price-text">单价</div>
            <div class="ad-content-fait">{{item.CurrencyInfo.EnName}}</div>
          </div>

          <div class="ad-content-item">
            <div class="ad-price"><span class="ad-price-symbol">{{item.CurrencyInfo.Symbol}}</span>{{item.Price}}</div>
          </div>

          <div class="ad-content-item ad-content-item-h10">
            <div class="ad-content-num">
              <div class="ad-content-amount-box">
                <div class="ad-content-amount-text">剩余数量</div>
                <div class="ad-content-amount">{{surplusAmount}} {{item.CoinName}}</div>
              </div>
              <div class="ad-content-money-box">
                <div class="ad-content-money-text">限额</div>
                <div class="ad-content-money">{{item.CurrencyInfo.Symbol}}{{item.Min <= 0 ? '不限额' : item.Min}} ~ {{item.CurrencyInfo.Symbol}}{{item.Max <= 0 ? '不限额' : item.Max}}</div>
              </div>
            </div>
            <div class="ad-content-buy" @click="contractSeller">联系商家</div>
          </div>

          <div class="ad-content-item ad-content-item-h20">
            <div class="ad-content-item-pay-list">
              <div class="ad-content-item-pay-list-item" v-for="p in item.PaymentInfo" :key="p.Id">
                <span class="ad-content-item-pay-list-item-line" :style="{color: p.Color}"></span>
                <span>{{p.EnName}}</span>
              </div>
            </div>
          </div>

          <div class="ad-content-item ad-content-item-h20">
            <div class="ad-content-item-remark">
              <div class="ad-content-item-remark-title">留言：</div>
              <div class="ad-content-item-remark-text">
                <my-text-overflow v-if="item.Remark" :text="item.Remark" :maxLines="3">
                  <template v-slot:default="{ clickToggle, expanded }">
                    <div class="ad-content-item-remark-text-overflow" @click="clickToggle">
                      {{ expanded ? "-收起" : "+展开" }}
                    </div>
                  </template>
                </my-text-overflow>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="form">
      <div class="btn-bottom">
        <div
            class="input-bg container-flex-h-center"
            style="margin-top: 11px; margin-left: 16px; margin-right: 16px"
        >
          <van-field
              label="数量"
              class="ad-input"
              rows="1"
              autosize
              v-model="buyAmount"
              input-align="right"
              label-class="ad-input-label"
              @input="adAmountUpdate"
          >
            <template #button>
              <div class="container-flex-h info-item">
                <div class="second-title">
                  {{ item.CoinName }}
                </div>
              </div>
            </template>
          </van-field>
        </div>

        <div
            class="input-bg container-flex-h-center"
            style="margin-top: 11px; margin-left: 16px; margin-right: 16px"
        >
          <van-field
              :label="item.Mold == 0 ? '应支付' : '可收购'"
              class="ad-input"
              rows="1"
              autosize
              v-model="amountText"
              disabled
              input-align="right"
              label-class="ad-input-label"
          >
            <template #button>
              <div class="container-flex-h info-item">
                <div class="second-title">
                  {{ item.CurrencyInfo.EnName || "--" }}
                </div>
              </div>
            </template>
          </van-field>
        </div>

        <div class="ad-input-tip">
          <div class="ad-input-tip-err">
            <span v-if="errInfo">* {{errInfo}}</span>
          </div>
          <div v-if="item.Mold === 1" class="ad-input-tip-info">可用 {{info.balance.div(usdtDecimals)}} {{item.CoinName || '--'}}</div>
        </div>

        <div class="ad-btn-box">
          <van-button
              v-if="info.approve || item.Mold == 0"
              type="primary"
              class="ad-btn"
              @click="buyAction"
              :loading="buyLoading"
          >{{ item.Mold == 0 ? '购买' : '卖出' }}{{item.CoinName}}
          </van-button>
          <van-button
              v-else
              type="primary"
              class="ad-btn"
              @click="approve"
              :loading="approveLoading"
          >授权{{item.CoinName}}
          </van-button>
        </div>

        <!-- tips -->

        <Tips class="ad-tip" type="ad"/>
      </div>
    </div>

  </div>
</template>
<script>
import Tips from "../components/Tips.vue";
import {AdvertiseDetails, OrderSubmit} from "../api/httpApi";
import BigNumber from "bignumber.js";
import {CreateOrder, CreateOrderInfo} from "../api/otc";
import {getOTCAddress} from "../utils/addressHelpers";
import {USDTApprove} from "../api/token";
import {getAccounts} from "../utils/wallet";
import {mapGetters} from "vuex";
import {isApp, sessionChat} from "../api/app";
import MyAvatar from "../components/my-avatar";
import MyTextOverflow from "../components/my-text-overflow";

export default {
  name: "AdvertiseDetail",
  components: {Tips,MyAvatar, MyTextOverflow},
  computed: {
    ...mapGetters(["protocol"]),
    DealRate() {
      if (this.item.MerchantInfo.TotalOrder <= 0) {
        return 0.00
      }
      return Number(this.item.MerchantInfo.DealOrder / this.item.MerchantInfo.TotalOrder * 100).toFixed(2)
    },
    amountText() {
      if (!this.buyAmount) {
        return "0.00"
      }
      return new BigNumber(this.buyAmount).times(new BigNumber(this.item.Price)).toFixed(2)
    },
    surplusAmount() {
      return new BigNumber(this.item.Num).minus(new BigNumber(this.item.Deal)).minus(new BigNumber(this.item.Pending))
    },
    getIcon() {
      return this.item.MerchantInfo.Nick.substring(0, 1)
    },
  },
  data() {
    return {
      account: "",
      showIntroduceAll: false,
      buyAmount: "",
      errInfo: "",
      AdId: 0,
      item: {
        MerchantInfo: {
          Address: "",
          Nick: "",
          Head: "",
          TotalOrder: 0,
          DealOrder: 0,
          ErrorOrder: 0,
          RevokeOrder: 0,
          LoadOrder: 0,
          SessionId: ""
        },
        CurrencyInfo: {},
        PaymentInfo: [],
        SessionId: "",
        Deal: 0,
        Num: 0,
        Min: 0,
        Max: 0,
        Price: 0,
        Pending: 0,
      },
      infoLoading: false,
      info: {
        approve: false,
        balance: new BigNumber(0),
        pause: false,
        userLockStatus: false,
        userMaxOrderCount: 0,
        getUserOrderLength: 0,
        getTraderStatus: false,
      },
      usdtDecimals: new BigNumber(1e18),
      approveLoading: false,
      buyLoading: false,
    };
  },
  created() {
    this.AdId = this.$route.query.adId || 0
    this.getAuthorizeInfo()
  },
  methods: {
    adAmountUpdate(value) {
      console.log(value)
      if (!value) {
        this.errInfo = ""
        return
      }
      const amount = new BigNumber(value)
      const amountBig = amount.times(this.usdtDecimals)
      const Price = new BigNumber(this.item.Price)
      const money = amount.times(Price)
      const MinAmount = new BigNumber(this.item.Min)
      const MaxAmount = new BigNumber(this.item.Max)
      this.errInfo = ""
      if (amount.comparedTo(this.surplusAmount) > 0) {
        this.errInfo = "输入的数量应小于库存"
        return
      }
      if (MinAmount.comparedTo(new BigNumber(0)) > 0 && money.comparedTo(MinAmount) < 0) {
        this.errInfo = "输入的数量应大于最小额度"
        return false
      }
      if (MaxAmount.comparedTo(new BigNumber(0)) > 0 && money.comparedTo(MaxAmount) > 0) {
        this.errInfo = "输入的数量应小于最大额度"
        return false
      }
      // 判断余额
      if (this.item.Mold === 1 && amountBig.comparedTo(this.info.balance) > 0) {
        this.errInfo = "余额不足"
        return false
      }

    },
    async getAuthorizeInfo() {
      const accounts = await getAccounts()
      this.account = accounts[0]
      await this.getInfo()
    },
    async getInfo() {
      this.infoLoading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner',
      })
      const res = await AdvertiseDetails({AdId: this.AdId})
      if (res.status !== 1) {
        this.infoLoading = false
        this.$toast.clear()
        return false
      }
      this.item = res.data
      const tokenAddress = this.item.Ext
      this.info = await CreateOrderInfo(tokenAddress, this.account, this.item.MerchantInfo.Address)
      console.log(this.info)
      this.infoLoading = false
      this.$toast.clear()
    },
    async approve() {
      console.dir("授权");
      if (this.approveLoading) {
        return
      }
      const otcAddress = getOTCAddress()
      this.approveLoading = true
      const res = await USDTApprove(otcAddress)
      this.approveLoading = false
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '授权取消',
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      this.$notify({
        type: 'success',
        message: '授权成功',
        duration: 2000,
        position: 'top-left',
      });
      this.info.approve = true
    },
    async buyAction() {
      console.dir("buyAction");
      const accounts = await getAccounts()
      if (accounts.length === 0) {
        this.$notify({
          type: 'warning',
          message: '请连接钱包',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      const account = accounts[0]

      const type = this.item.Mold == 0 ? 1 : 0
      const amount = new BigNumber(this.buyAmount)
      const amountBig = amount.times(this.usdtDecimals)
      const Price = new BigNumber(this.item.Price)
      const money = amount.times(Price)
      const MinAmount = new BigNumber(this.item.Min)
      const MaxAmount = new BigNumber(this.item.Max)
      if (!this.buyAmount) {
        this.$notify({
          type: 'warning',
          message: '请输入数量',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (amount.comparedTo(this.surplusAmount) > 0) {
        this.$notify({
          type: 'warning',
          message: '输入的数量应小于库存',
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      if (MinAmount.comparedTo(new BigNumber(0)) > 0 && money.comparedTo(MinAmount) < 0) {
        this.$notify({
          type: 'warning',
          message: '输入的数量应大于最小额度',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (MaxAmount.comparedTo(new BigNumber(0)) > 0 && money.comparedTo(MaxAmount) > 0) {
        this.$notify({
          type: 'warning',
          message: '输入的数量应小于最大额度',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      // 判断余额
      if (this.item.Mold === 1 && amountBig.comparedTo(this.info.balance) > 0) {
        this.$notify({
          type: 'warning',
          message: '余额不足',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (this.info.pause) {
        this.$notify({
          type: 'warning',
          message: '合约尚未开放',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (this.info.userLockStatus) {
        this.$notify({
          type: 'warning',
          message: '你当前尚有订单未完成',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (this.info.getUserOrderLength >= this.info.userMaxOrderCount) {
        this.$notify({
          type: 'warning',
          message: `您还有未处理的订单, 请完成处理后再操作 !`,
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (!this.info.getTraderStatus) {
        this.$notify({
          type: 'warning',
          message: `该商户信息异常, 可能存在风险, 请刷新查看或选择其他商户下单!`,
          duration: 2000,
          position: 'top-left',
        });
        return false
      }

      const Deal = new BigNumber(this.item.Deal)
      const Pending = new BigNumber(this.item.Pending)
      const Num = new BigNumber(this.item.Num)
      const diff = Num.minus(Pending).minus(Deal)
      if (amount.comparedTo(diff) > 0) {
        this.$notify({
          type: 'warning',
          message: `该广告单库存不足, 请刷新查看或选择其他广告下单!`,
          duration: 2000,
          position: 'top-left',
        });
        return false
      }


      this.buyLoading = true
      const res = await CreateOrder(type, this.AdId, amountBig.toString())
      this.buyLoading = false

      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '下单失败',
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      this.$notify({
        type: 'success',
        message: '操作成功',
        duration: 2000,
        position: 'top-left',
      });

      const hash = res.transactionHash

      const events = res.events
      const item = events.find(item => item.event === "CreateOrder") || {}
      const orderId = item.args.orderId.toNumber()
      console.log("交易信息", item, orderId)

      const data = {
        Address: account,
        AdId: this.AdId,
        OrderId: orderId,
        Num: this.buyAmount,
        Protocol: this.protocol,
        CoinId: this.item.CoinId,
        OrderHash: hash,
        Mold: type,
        CurrencyId: this.item.CurrencyInfo.Id
      }
      OrderSubmit(data).then(res => {
        this.$router.push(`/order-detail?orderId=${orderId}`);
      })
      .catch(e => {
        console.log("下订单报错", e)
      })

    },
    contractSeller() {
      if (!isApp()) {
        this.$dialog.alert({
          title: '温馨提示',
          message: '请在CoinSafe钱包App中使用密语功能, 若还未安装,请点击下载 !',
          theme: 'round-button',
          confirmButtonColor: '#3665B7',
        }).then(() => {
          window.open(this.systemSetting.AndroidDownload)
        })
        return false
      }

      if (this.item.SessionId.length === 0) {
        this.$dialog.alert({
          title: '温馨提示',
          message: '当前商家没有提供联系方式',
          theme: 'round-button',
        })
        return false
      }
      // 打开聊天
      sessionChat(this.item.SessionId)
    },

  },
};
</script>
<style lang="less" scoped>
.ad-item-box {
  padding-top: 30px;
  background-color: #F7F7F7;
}
.ad-item {
  padding: 36px 40px 18px;
  background-color: #FFFFFF;
  border-radius: 50px 50px 0 0;
}

.ad-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ad-header-user {
  display: flex;
  align-items: center;
}

.ad-header-user-avatar {
  margin-right: 11px;
}

.ad-header-user-name {
  color: #333333;
  font-weight: bold;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  max-width: 100px;
}

.ad-header-user-address-box {
  display: flex;
  margin-left: 6px;
}

.ad-header-user-address {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  max-width: 100px;
  direction: rtl;
  color: #666666;
}

.ad-header-num {
  color: #666666;
}

.ad-content {
  margin-top: 40px;
  padding: 0 5px;
}

.ad-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ad-content-item-h10 {
  margin-top: 10px;
}

.ad-content-item-h20 {
  margin-top: 20px;
}

.ad-content-item-bottom {
  align-items: flex-end;
}

.ad-price-text {
  color: #999999;
}

.ad-content-fait {
  background-color: #E7AF4F;
  border-radius: 10px;
  padding: 0 17px;
  color: #FFFFFF;
}

.ad-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 48px;
  font-weight: 800;
}

.ad-price-symbol {
  padding-top: 6px;
  padding-right: 6px;
  margin-left: -5px;
  font-size: 26px;
}

.ad-content-num {
}

.ad-content-amount-box {
  display: flex;
  align-items: center;
}

.ad-content-amount-text {
  color: #999999;
  font-size: 24px;
  margin-right: 19px;
}

.ad-content-amount {
  color: #333333;
  font-size: 26px;
  font-weight: bold;
}

.ad-content-money-box {
  display: flex;
  align-items: center;
}

.ad-content-money-text {
  color: #999999;
  font-size: 24px;
  margin-right: 19px;
}

.ad-content-money {
  color: #333333;
  font-size: 26px;
  font-weight: bold;
}

.ad-content-buy {
  color: #779CDC;
  font-size: 30px;
  font-weight: bold;
  background-color: #FFFFFF;
  border: 2px solid #779CDC;
  padding: 10px 48px;
  border-radius: 10px;
}

.ad-content-item-pay-list {
  color: #333333;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.ad-content-item-pay-list-item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.ad-content-item-pay-list-item-line {
  //margin-top: 2px;
  width: 3px;
  height: 22px;
  background-color: #03F474;
  margin-right: 10px;
}

.ad-content-item-remark {
  display: flex;
}

.ad-content-item-remark-title {
  color: #333333;
  font-size: 24px;
  font-weight: 500;
  white-space: nowrap;
}

.ad-content-item-remark-text {
  //flex: 1;
  text-align: left;
  color: #666666;
}
.ad-content-item-remark-text-overflow {
  color: #779CDC;

}

.second-title {
  font-size: 24px;
  font-weight: 400;
  color: #BDBDBD;
}

.ad-input {
  background-color: #F5F5F5;
  border-radius: 10px;
}

.ad-input-tip {
  margin-top: 20px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ad-input-tip-err {
  font-size: 26px;
  color: #E75D4F;
  font-weight: 500;
}

.ad-input-tip-info {
  font-size: 24px;
  color: #999999;
  font-weight: 500;
}

.ad-btn-box {
  margin-top: 68px;
  display: flex;
  justify-content: center;
}

.ad-btn {
  padding: 25px 0;
  width: 360px;
  font-size: 30px;
  margin-left: 16px;
  margin-right: 16px;
  color: #FFFFFF;
  border: none;
  background-color: #3665B7;
  border-radius: 20px;
}

.ad-tip {
  margin-top: 40px;
}

</style>

<style>

.ad-input-label {
  color: #c8c9cc;
}
</style>