import {getUSDTContract} from "../utils/contractHelpers";
import {MAX_INT} from "../config";

export const USDTApprove = async (account, amount) => {
    const contract = getUSDTContract(true)
    amount = amount ? amount : MAX_INT
    try {
        const tx = await contract.approve(account, amount)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    }catch (e) {
        console.log("授权失败", e)
        return false
    }
}